<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div>
		学生管理
	</div>
</template>
<script>
	export default {
		data() {
			return {}
		},
		methods: {},
	}
</script>
<style lang="scoped">

</style>
